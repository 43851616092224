import { find } from 'lodash'
import ReactPlayer from 'react-player'
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap'
import { ReactElement, useMemo, useRef, useState } from 'react'
import { Video } from '../../types'
import { useElementSize } from 'usehooks-ts'
import { scaleLinear } from 'd3-scale'
import { GoogleMap, MarkerF, StreetViewPanorama, useLoadScript } from '@react-google-maps/api'

function betweenTime(playedSeconds: number) {
  return ({ start, end }: { start: number; end: number }) => {
    const intSeconds = playedSeconds
    return intSeconds >= start && intSeconds <= end
  }
}

interface AnnotationsWithTime {
  coordinates: any
  start: number
  end: number
  id: number
  video: number | null
  label: string
  annotation_type: string
  text_content: string
  image_content: string | null
}

interface VideoPlayerProps {
  annotationsWithTime: AnnotationsWithTime[] | undefined
  video: Video | undefined
  containerClassName?: string
  playerClassName?: string
  contextAnnotationClassName?: string
  mediaAnnotationClassName?: string
  renderCustomControls?: (
    videoState: {
      durationSeconds: number
      loaded: number
      loadedSeconds: number
      playedSeconds: number
      played: number
      volume: number
      playing: boolean
    },
    setVideoState: React.Dispatch<
      React.SetStateAction<{
        durationSeconds: number
        loaded: number
        loadedSeconds: number
        playedSeconds: number
        played: number
        volume: number
        playing: boolean
      }>
    >
  ) => ReactElement
  reactPlayerProps?: {
    width: number | string
    height: number | string
    controls: boolean
  }
}

export default function VideoPlayer({
  annotationsWithTime,
  video: dataVideo,
  containerClassName = 'row mt-2',
  playerClassName = 'col-md-8',
  contextAnnotationClassName = 'col-md-4',
  mediaAnnotationClassName = '',
  renderCustomControls,
  reactPlayerProps,
}: VideoPlayerProps) {
  const [videoState, setVideoState] = useState({
    durationSeconds: 0,
    loaded: 0,
    loadedSeconds: 0,
    playedSeconds: 0,
    played: 0,
    volume: 1,
    playing: false,
  })
  const playerRef = useRef<ReactPlayer>(null)

  const [hoverAnnotation, setHoverAnnotation] = useState<number | null>(null)

  const currentDoc = useMemo(() => {
    const annotations = annotationsWithTime?.filter(
      (d) => d.annotation_type !== 'text'
    )
    const playedSeconds = videoState.playedSeconds
    const current = find(annotations, betweenTime(playedSeconds))
    return current
  }, [annotationsWithTime, videoState.playedSeconds])

  const currentPersona = useMemo(() => {
    const annotations = annotationsWithTime?.filter(
      (d) => d.annotation_type === 'text'
    )
    const playedSeconds = videoState.playedSeconds
    const current = find(annotations, betweenTime(playedSeconds))
    return current
  }, [annotationsWithTime, videoState.playedSeconds])

  const [videoDuration, setVideoDuration] = useState(0)

  const [mediaTrackRef, { width: mediaTrackWidth }] =
    useElementSize()

  const mediaTrackScale = useMemo(() => {
    return scaleLinear().domain([0, videoDuration]).range([0, mediaTrackWidth ?? 0])
  }, [mediaTrackWidth, videoDuration])

  const annotationsPersons = useMemo(() => {
    return annotationsWithTime?.filter(
      (d) => d.annotation_type === 'text'
    ) as AnnotationsWithTime[]
  }, [annotationsWithTime])

  const annotationsSchede = useMemo(() => {
    return annotationsWithTime?.filter(
      (d) => d.annotation_type !== 'text'
    ) as AnnotationsWithTime[]
  }, [annotationsWithTime])

  const libraries = useMemo(() => ['places', 'streetView'], [])

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBZJoL4CWgHBzl15nFez-Pal5QOLOduuf0' as string,
    libraries: libraries as any,
  })

  return (
    <div className={containerClassName}>
      <div className={playerClassName}>
        {dataVideo?.file_url_vimeo && (
          <ReactPlayer
            controls
            ref={playerRef}
            onReady={(e) => {
              setVideoDuration(e.getDuration())
            }}
            onClick={() => {
              setVideoState((state) => ({
                ...state,
                playing: !state.playing,
              }))
            }}
            //   onReady={this.onPlayerReady}
            onDuration={(duration) => {
              setVideoState((state) => ({
                ...state,
                durationSeconds: duration,
              }))
            }}
            onProgress={(stateProgress) => {
              setVideoState((state) => ({
                ...state,
                loaded: stateProgress.loaded,
                loadedSeconds: stateProgress.loadedSeconds,
                playedSeconds: stateProgress.playedSeconds,
                played: stateProgress.played,
              }))
            }}
            playing={videoState.playing}
            volume={videoState.volume}
            width="100%"
            height={300}
            progressInterval={500}
            url={dataVideo?.file_url_vimeo}
            {...reactPlayerProps}
          />
        )}
      </div>
      <div className={contextAnnotationClassName}>
        {currentPersona && (
          <div>
            <h5>{currentPersona.text_content}</h5>
          </div>
        )}
        {currentDoc && (
          <div className={mediaAnnotationClassName}>
            {currentDoc.annotation_type === 'text_image' &&
              currentDoc.image_content && (
                <div className="mt-2">
                  <img
                    className="img-fluid"
                    src={currentDoc.image_content}
                    alt=""
                  />
                  <p>
                    <small>{currentDoc.text_content}</small>
                  </p>
                </div>
              )}
            {currentDoc.annotation_type === 'map' && currentDoc.coordinates && isLoaded && (
              <>
                <GoogleMap
                  // options={mapOptions}
                  zoom={12}
                  options={{
                    zoomControl: false,
                    fullscreenControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    draggable: false,
                    scrollwheel: false,
                    disableDoubleClickZoom: true,
                  }}
                  center={{
                    lat: parseFloat(currentDoc.coordinates.coordinates[1]),
                    lng: parseFloat(currentDoc.coordinates.coordinates[0]),
                  }}
                  // mapTypeId={google.maps.MapTypeId.ROADMAP}
                  mapContainerStyle={{ width: '100%', height: '380px' }}
                  onLoad={() => console.log('Map Component Loaded...')}
                >
                  <MarkerF
                    position={{
                      lat: parseFloat(currentDoc.coordinates[1]),
                      lng: parseFloat(currentDoc.coordinates[0]),
                    }}
                    animation={google.maps.Animation.DROP}
                  />
                </GoogleMap>
                <div>
                  <div>{currentDoc.label}</div>
                  <div>
                    {currentDoc.text_content && (
                      <div>{currentDoc.text_content}</div>
                    )}
                  </div>
                </div>
              </>
            )}
            {currentDoc.annotation_type === 'streetview' &&
              currentDoc.coordinates && isLoaded && (
                <GoogleMap
                  // options={mapOptions}
                  zoom={12}
                  center={{
                    lat: currentDoc.coordinates.coordinates[1],
                    lng: currentDoc.coordinates.coordinates[0],
                  }}
                  // mapTypeId={google.maps.str}
                  // mapTypeId={google.maps.MapTypeId.ROADMAP}
                  mapContainerStyle={{ width: '100%', height: '380px' }}
                  // onLoad={() => console.log('Map Component Loaded...')}
                >
                  <StreetViewPanorama
                    options={{
                      position: {
                        lat: currentDoc.coordinates.coordinates[1],
                        lng: currentDoc.coordinates.coordinates[0],
                      },
                      visible: true,
                    }}
                  />
                  <div >
                    <div>{currentDoc.label}</div>
                    <div>
                      {currentDoc.text_content && (
                        <div>{currentDoc.text_content}</div>
                      )}
                    </div>
                  </div>
                </GoogleMap>
              )}
          </div>
        )}
      </div>
      {renderCustomControls && (
        <div className="custom-controls">
          {renderCustomControls(videoState, setVideoState)}
        </div>
      )}
      <div className="container-fluid mt-3">
        Persone timeline
        <div className="track position-relative mt-1">
          <div className="track-media-annotations" ref={mediaTrackRef}></div>
          <div className="track-context-annotations d-flex">
            {annotationsPersons?.map((d, i) => {
              const start = mediaTrackScale(d.start)
              const end = mediaTrackScale(d.end)
              return (
                <div
                  key={i}
                  className="track-context-annotation position-absolute"
                  style={{
                    height: 10,
                    left: start,
                    width: end - start,
                    backgroundColor: 'var(--primary)',
                  }}
                >
                  <div
                    style={{
                      width: 12,
                      height: 12,
                      marginTop: -1,
                      borderRadius: '50%',
                    }}
                    id={'annotation_' + d.id}
                    onMouseEnter={() => {
                      setHoverAnnotation(d.id)
                    }}
                    onMouseLeave={() => {
                      setHoverAnnotation(null)
                    }}
                    className="bg-white border border-dark"
                  ></div>
                  {hoverAnnotation && hoverAnnotation === d.id && (
                    <Popover
                      placement="bottom"
                      isOpen={hoverAnnotation === d.id}
                      target={'annotation_' + d.id}
                      toggle={() => {
                        setHoverAnnotation(null)
                      }}
                    >
                      <PopoverHeader>{d.label}</PopoverHeader>
                      <PopoverBody>{d.text_content}</PopoverBody>
                    </Popover>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="container-fluid mt-3">
        Schede timeline
        <div className="track position-relative mt-1">
          <div className="track-media-annotations" ref={mediaTrackRef}></div>
          <div className="track-context-annotations d-flex">
            {annotationsSchede?.map((d, i) => {
              const start = mediaTrackScale(d.start)
              const end = mediaTrackScale(d.end)
              return (
                <div
                  key={i}
                  className="track-context-annotation position-absolute"
                  style={{
                    height: 10,
                    left: start,
                    width: end - start,
                    backgroundColor: 'var(--primary)',
                  }}
                >
                  <div
                    style={{
                      width: 12,
                      height: 12,
                      marginTop: -1,
                      borderRadius: '50%',
                    }}
                    id={'annotation_' + d.id}
                    onMouseEnter={() => {
                      setHoverAnnotation(d.id)
                    }}
                    onMouseLeave={() => {
                      setHoverAnnotation(null)
                    }}
                    className="bg-white border border-dark"
                  ></div>
                  {hoverAnnotation && hoverAnnotation === d.id && (
                    <Popover
                      placement="bottom"
                      isOpen={hoverAnnotation === d.id}
                      target={'annotation_' + d.id}
                      toggle={() => {
                        setHoverAnnotation(null)
                      }}
                    >
                      <PopoverHeader>{d.label}</PopoverHeader>
                      <PopoverBody>{d.text_content}</PopoverBody>
                    </Popover>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
