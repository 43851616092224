import { Field, Formik } from 'formik'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Annotation, BaseAnnotation } from '../types'
import {
  FileField,
  InputField,
  MapField,
  SelectField,
  TextareaField,
  transformErrorForForm,
} from './form'
import L from 'leaflet'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { useGeolocation } from '../hooks/geolocation'

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

interface Props {
  annotation?: Annotation
  save: (annotation: Annotation) => Promise<unknown>
  isOpen: boolean
  toggle?: (x: any) => void
  video: number
}

const initialValues: BaseAnnotation = {
  annotation_type: '',
  start: '',
  end: '',
  text_content: '',
  coordinates: null,
  label: '',
  image_content: null,
  audio_content: null,
  link: '',
  video: null,
}

const AnnotationSchema = Yup.object().shape({
  label: Yup.string().required().label('Label'),
  annotation_type: Yup.string().required().label('Tipo scheda'),
  start: Yup.string().required().label('Inizio'),
  end: Yup.string().required().label('Fine'),
})

export default function ModalFormAnnotation({
  annotation,
  save,
  isOpen,
  toggle,
  video,
}: Props) {
  const [city, setCity] = useState('')
  const [address, setAddress] = useState('')
  const geolocationAction = useGeolocation()
  const [nonFieldsErrors, setNonFieldsErrors] = useState<string[]>([])

  useEffect(() => {
    setNonFieldsErrors([])
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size={'md'} centered>
      <ModalHeader toggle={toggle}>
        {annotation ? 'Modifica scheda' : 'Nuova scheda'}
      </ModalHeader>
      <Formik
        validationSchema={AnnotationSchema}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          save({ ...values, video }).catch((err) => {
            if (err.response?.data?.non_field_errors.length) {
              setNonFieldsErrors(err.response.data.non_field_errors)
            }
            setErrors(transformErrorForForm(err))
            setSubmitting(false)
          })
        }}
        validateOnMount
        enableReinitialize
        initialValues={
          (annotation as Annotation) ?? (initialValues as Annotation)
        }
      >
        {({
          handleSubmit,
          isSubmitting,
          isValid,
          errors,
          values,
          setFieldValue,
        }) => {
          console.log(isSubmitting)
          return (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <div className="row">
                  <div className="col-md-12">
                    <Field
                      name="annotation_type"
                      required
                      placeholder="Tipo"
                      label={'Tipo'}
                      component={SelectField}
                    >
                      <option value="">Seleziona un tipo</option>
                      <option value="text">Persona</option>
                      <option value="text_image">Immagine e testo</option>
                      <option value="map">Mappa</option>
                      <option value="audio">Audio</option>
                      <option value="streetview">Street view</option>
                    </Field>
                  </div>
                  <div className="col-md-12">
                    <Field
                      required
                      label={
                        values.annotation_type === 'text'
                          ? 'Nome intervistato'
                          : values.annotation_type === 'text_image'
                          ? 'Titolo immagine'
                          : values.annotation_type === 'map'
                          ? 'Titolo mappa'
                          : values.annotation_type === 'audio'
                          ? 'Titolo audio'
                          : 'Label'
                      }
                      name="label"
                      placeholder={
                        values.annotation_type === 'text'
                          ? 'Nome intervistato'
                          : values.annotation_type === 'text_image'
                          ? 'Titolo immagine'
                          : values.annotation_type === 'map'
                          ? 'Titolo mappa'
                          : values.annotation_type === 'audio'
                          ? 'Titolo audio'
                          : 'Label'
                      }
                      component={InputField}
                    />
                  </div>

                  {values.annotation_type === 'text_image' && (
                    <div className="col-md-12">
                      <Field
                        label={'Immagine'}
                        name="image_content"
                        placeholder="Immagine"
                        fileType="image"
                        component={FileField}
                      />
                    </div>
                  )}
                  {values.annotation_type === 'audio' && (
                    <div className="col-md-12">
                      <Field
                        label={'Audio'}
                        name="audio_content"
                        placeholder="Audio"
                        fileType="audio"
                        component={FileField}
                      />
                    </div>
                  )}
                  <div className="col-md-12">
                    <Field
                      label={
                        values.annotation_type === 'text'
                          ? 'Ruolo intervistato'
                          : values.annotation_type === 'text_image'
                          ? 'Testo'
                          : values.annotation_type === 'map'
                          ? 'Descrizione mappa'
                          : 'Testo'
                      }
                      name="text_content"
                      className="form-control"
                      placeholder={
                        values.annotation_type === 'text'
                          ? 'Ruolo intervistato'
                          : values.annotation_type === 'text_image'
                          ? 'Testo'
                          : values.annotation_type === 'map'
                          ? 'Descrizione mappa'
                          : 'Testo'
                      }
                      rows={5}
                      component={TextareaField}
                    />
                  </div>
                  {(values.annotation_type === 'map' ||
                    values.annotation_type === 'streetview') && (
                    <>
                      <div className="col-md-12">
                        <Field
                          name="address"
                          component={InputField}
                          label={'Indirizzo'}
                          value={address}
                          onChange={(e: any) => {
                            setAddress(e.target.value)
                          }}
                        />
                      </div>
                      <div className="col-md-12">
                        <Field
                          name="city"
                          component={InputField}
                          label={'Città'}
                          value={city}
                          onChange={(e: any) => {
                            setCity(e.target.value)
                          }}
                        />
                      </div>
                      <div className="col-md-4 mt-1">
                        <Button
                          disabled={!city || !address}
                          color="primary"
                          size="sm"
                          onClick={async () => {
                            const geo = await geolocationAction({
                              city: city,
                              address: address,
                            })
                            setFieldValue('coordinates', {
                              type: 'Point',
                              coordinates: [geo.lng, geo.lat],
                            })
                          }}
                        >
                          Cambia posizione
                        </Button>
                      </div>
                      <div className="col-md-12">
                        <div className="col-md-12 mt-3">
                          <Field name="coordinates" component={MapField} />
                        </div>
                      </div>
                    </>
                  )}
                  {values.annotation_type !== 'text' && (
                    <div className="col-md-12">
                      <Field
                        label={'Link esterno'}
                        name="link"
                        placeholder="Link"
                        component={InputField}
                      />
                    </div>
                  )}
                  <div className="col-md-12">
                    <Field
                      label={'Inizio'}
                      name="start"
                      required
                      type={'time'}
                      placeholder="Inizio"
                      component={InputField}
                    />
                  </div>
                  <div className="col-md-12">
                    <Field
                      label={'Fine'}
                      name="end"
                      required
                      type={'time'}
                      placeholder="Fine"
                      component={InputField}
                    />
                  </div>
                </div>
                {nonFieldsErrors.length > 0 && (
                  <div className="col-md-12">
                    <div className="alert alert-danger" role="alert">
                      {nonFieldsErrors.map((error) => (
                        <div key={error}>{error}</div>
                      ))}
                    </div>
                  </div>
                )}
              </ModalBody>
              <ModalFooter className="d-flex justify-content-between">
                <button
                  type="button"
                  onClick={toggle}
                  disabled={isSubmitting}
                  className="btn btn-outline-primary"
                >
                  Annulla
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting || !isValid}
                  className="btn btn-success"
                >
                  <i className="text-white me-1 bi bi-save-fill"></i> Salva
                </button>
              </ModalFooter>
            </form>
          )
        }}
      </Formik>
    </Modal>
  )
}
