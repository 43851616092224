import { Route, Routes } from 'react-router'
import NotFound from '../../components/NotFound'
import VideoAnnotations from './VideoAnnotations'
import VideoCreate from './VideoCreate'
import VideoEdit from './VideoEdit'
import VideoList from './VideoList'

export default function Video() {
  return (
    <Routes>
      <Route index element={<VideoList />} />
      <Route path=":id" element={<VideoEdit />} />
      <Route path=":id/annotations" element={<VideoAnnotations />} />
      <Route path="new" element={<VideoCreate />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
