import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Header from '../../components/Header'
import ModalFormAnnotation from '../../components/ModalFormAnnotation'
import Page from '../../components/Page'
import Sorter, { SortControl } from '../../components/Sorter'
import StickyTable from '../../components/StickyTable'
import { ANNOTATIONS_TYPES } from '../../consts'
import {
  useAnnotations,
  useCreateAnnotation,
  useDeleteAnnotation,
  useUpdateAnnotation,
} from '../../hooks/annotations'
import { useQsFilters } from '../../hooks/filters'
import { useVideo } from '../../hooks/video'
import { Annotation } from '../../types'
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer'

const initFilters = (params: URLSearchParams) => ({
  ordering: params.get('ordering') ?? '',
  video: params.get('video') ?? '',
})

function timetoDecimal(time: string) {
  const timeToUse = time.substring(0, 5)
  const timeSplitted = timeToUse.split(':')
  return parseInt(timeSplitted[0]) * 60 + parseInt(timeSplitted[1])
}

export default function VideoAnnotations() {
  const { filters, uiFilters, setFilters } = useQsFilters(initFilters)
  const { id } = useParams()

  const filtersWithVideo = useMemo(() => {
    return {
      ...filters,
      video: id,
    }
  }, [filters, id])

  const { data: dataVideo } = useVideo(+id!)
  const { data: annotations } = useAnnotations(filtersWithVideo)
  const deleteScheda = useDeleteAnnotation()
  const [modalRm, modalRmActions] = useModalTrigger<Annotation>()
  const [modalCreate, modalCreateActions] = useModalTrigger()
  const [modalUpdate, modalUpdateActions] = useModalTrigger<Annotation>()
  const createAnnotation = useCreateAnnotation()
  const updateAnnotation = useUpdateAnnotation()

  const annotationsWithTime = useMemo(() => {
    return annotations?.map((annotation) => {
      const start = timetoDecimal(annotation.start)
      const end = timetoDecimal(annotation.end)

      return {
        ...annotation,
        start,
        end,
      }
    })
  }, [annotations])

  return (
    <Page padded className="overflow-scroll">
      <Header>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/video">Video</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={'/video/' + dataVideo?.id}>{dataVideo?.title}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Schede
            </li>
          </ol>
        </nav>
        <button
          className="btn btn-primary"
          onClick={() => {
            modalCreateActions.open()
          }}
        >
          <i className="bi bi-plus-circle me-2"></i> Aggiungi
        </button>
      </Header>
      <div className="h-100 overflow-auto">
        <VideoPlayer
          reactPlayerProps={{
            controls: true,
            width: '100%',
            height: 300,
          }}
          annotationsWithTime={annotationsWithTime}
          video={dataVideo}
        />
        <StickyTable className="flex-1 mt-3 overflow-hidden">
          <Sorter
            value={uiFilters.ordering}
            onSortChange={(ordering) =>
              setFilters({
                ordering,
              })
            }
          >
            <thead>
              <tr>
                <th>#</th>
                <th>
                  <div className="d-flex align-items-center">
                    Label
                    <SortControl field={'label'} />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    Tipo
                    <SortControl field={'annotation_type'} />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    Inizio
                    <SortControl field={'start'} />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    Fine
                    <SortControl field={'end'} />
                  </div>
                </th>
                <th></th>
              </tr>
            </thead>
          </Sorter>
          <tbody>
            {annotations!.map((scheda) => (
              <tr key={scheda.id}>
                <td>{scheda.id}</td>
                <td>{scheda.label}</td>
                <td>{ANNOTATIONS_TYPES[scheda.annotation_type]}</td>
                <td>{scheda.start.substring(0, 5)}</td>
                <td>{scheda.end.substring(0, 5)}</td>
                <td>
                  <i
                    onClick={() => modalUpdateActions.open(scheda)}
                    className="bi cursor-pointer text-primary bi-pencil-fill me-2"
                  ></i>
                  <i
                    onClick={() => modalRmActions.open(scheda)}
                    className="bi cursor-pointer text-danger bi-trash3-fill"
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </StickyTable>
      </div>
      <ModalFormAnnotation
        isOpen={modalCreate.isOpen}
        toggle={modalCreateActions.toggle}
        save={async (annotation) => {
          await createAnnotation.mutateAsync(annotation)
          modalCreateActions.close()
        }}
        video={Number(id)}
      />
      <ModalFormAnnotation
        isOpen={modalUpdate.isOpen}
        toggle={modalUpdateActions.toggle}
        save={async (annotation) => {
          await updateAnnotation.mutateAsync(annotation)
          modalUpdateActions.close()
        }}
        video={Number(id)}
        annotation={modalUpdate.value ?? undefined}
      />
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare la scheda ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn  btn-danger"
            disabled={deleteScheda.isLoading}
            onClick={async () => {
              await deleteScheda.mutateAsync(modalRm.value!.id)
              modalRmActions.close()
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
    </Page>
  )
}
