import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { PaginatedDJResponse, Video } from '../types'
import { makeFormDataEncoder, QueryOnlyOptions } from './utils'

export function useVideos(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Video>>(
    ['videos', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/videos/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDeleteVideo() {
    const client = useQueryClient()
    return useMutation(
      useAuthCallPromise(
        (token: string) => (id: number) =>
          axios.delete(`/api/admin/videos/${id}/`, {
            headers: { Authorization: `Bearer ${token}` },
          })
      ),
      {
        onSuccess() {
          client.invalidateQueries(['videos'])
        },
      }
    )
  }

  const makeVideoFormData = makeFormDataEncoder({
    fileFields: ['allegato'],
    excludeFields: [],
  })

  export function useCreateVideo() {
    const client = useQueryClient()
    return useMutation(
      useAuthCallPromise(
        (token: string) => (video: Video) =>
          axios
            .post(`/api/admin/videos/`, makeVideoFormData(video), {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data as Video)
      ),
      {
        onSuccess(savedVideo) {
          client.invalidateQueries(['videos'])
          client.setQueryData(['video', savedVideo.id], savedVideo)
        },
      }
    )
  }

  export function useVideo(id: number, queryOptions?: QueryOnlyOptions) {
    return useQuery<Video>(
      ['video', id],
      useAuthCallPromise(
        (token: string) =>
          ({ signal }) =>
            axios
              .get(`/api/admin/videos/${id}/`, {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((r) => r.data)
      ),
      queryOptions
    )
  }

  export function useUpdateVideo() {
    const client = useQueryClient()
    return useMutation(
      useAuthCallPromise(
        (token: string) => (video: Video) =>
          axios
            .put(`/api/admin/videos/${video.id}/`, makeVideoFormData(video), {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data as Video)
      ),
      {
        onSuccess(savedVideo) {
          client.invalidateQueries(['videos'])
          client.setQueryData(['video', savedVideo.id], savedVideo)
        },
      }
    )
  }
  